import { render, staticRenderFns } from "./AgencyVolunteersSection.vue?vue&type=template&id=483643e8&scoped=true&"
import script from "./AgencyVolunteersSection.vue?vue&type=script&lang=js&"
export * from "./AgencyVolunteersSection.vue?vue&type=script&lang=js&"
import style0 from "./AgencyVolunteersSection.vue?vue&type=style&index=0&id=483643e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "483643e8",
  null
  
)

export default component.exports